<template>
  <div
    class="widgetContainer bg-white widgetContainer--scrollable pay"
    :class="{ 'pay--completed': paymentStatus !== '' }">
    <div class="widgetContainer__header-fixed" v-if="paymentStatus == ''">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <span class="icon-Arrow-big-left cancel" @click="cancel" />
          <p class="title">
            {{ getSelectedContactData.name }}
          </p>  
        </div>
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="payment-done-header" v-if="paymentStatus !== ''">
        <LottieAnimation animation-name="success" reference="pay-payment-done" :loop="true" />
        
        <p class="title">
          ${{ $t('paid_amount_title', { value1: formData.amount }) }}
        </p>
        <p class="description" v-if="getPaymentType === 'ach'">
          {{
            $t('pay_success_description', {
              value1: getSelectedContactData.name
            })
          }}
        </p>
        <p class="description" v-else>
          {{ $t('pay_intra_success_description') }}
        </p>
      </div>
      <div v-else>
        <p class="header-1">
          {{ $t('transfer_money') }}
        </p>
      </div>

      <div class="pay__details">
        <el-form class="wise-form" v-if="paymentStatus == ''">
          <el-form-item
            class="is-no-asterisk"
            prop="amount"
            :label="$t('pay_section_amount')">
            <Amount :precision="2" v-model="formData.amount" :masked="true" />
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="description"
            :label="$t('pay_row_purpose')">
            <el-input
              placeholder="Purpose of the payment"
              v-model="formData.description" />
          </el-form-item>
        </el-form>

        <h4 class="header-1">
          {{ $t('pay_section_source').toLowerCase() }}
        </h4>
        <div class="listWithInfo border-on">
          <ul>
            <li>
              <span class="label">{{ $t('pay_row_from') }}</span>
              <span class="value">
                {{ getSelectedAccount.label }}
              </span>
            </li>
            <li>
              <span class="label">{{ $t('pay_row_accName') }}</span>
              <span class="value">
                Spend {{ getSelectedAccount.accountNumber }}
              </span>
            </li>
            <li v-if="paymentInfo !== ''">
              <span class="label">{{ $t('pay_row_purpose') }}</span>
              <span class="value">
                {{ paymentInfo.description }}
              </span>
            </li>
          </ul>
        </div>

        <h4 class="header-1">
          {{ $t('pay_section_destination').toLowerCase() }}
        </h4>
        <div class="listWithInfo border-on">
          <ul>
            <li>
              <span class="label">{{ $t('pay_row_accName') }}</span>
              <span class="value">{{ getSelectedContactData.name }}</span>
            </li>
            <li>
              <span class="label">{{ $t('contact_AccountNumber_title') }}</span>
              <span class="value">
                {{ getSelectedContactData[getPaymentType].accountNumber }}
              </span>
            </li>
            <li v-if="getPaymentType === 'ach'">
              <span class="label">{{ $t('contact_RoutingNumber_title') }}</span>
              <span class="value">
                {{ getSelectedContactData[getPaymentType].routingNumber }}
              </span>
            </li>
            <li v-if="getPaymentType === 'ach'">
              <span class="label">{{ $t('contact_Account_Bank') }}</span>
              <span class="value">
                {{ getSelectedContactData[getPaymentType].bankName }}
              </span>
            </li>
            <li v-if="getPaymentType === 'ach'">
              <span class="label">{{ $t('contact_AccountType_title') }}</span>
              <span class="value">{{ accountType }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>      
      <el-button
        type="primary"
        class="width-100 el-button__brand brand"
        v-if="paymentStatus !== ''"
        @click="done">
        {{ $t('done') }}
      </el-button>
      <el-button 
        @click="next()" 
        v-else
        :disabled="!isFormValid">
        {{ $t('pay') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { accountType } from '@m/contact/enums';
import Amount from '@/components/Amount';
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  components: {
    Amount,
    LottieAnimation
  },
  created() {
    if (!this.getPaymentType) this.$router.push('/pay/paymentType');
  },
  computed: {
    ...mapGetters('pay', ['getPaymentType']),
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    accountType() {
      return accountType[
        this.getSelectedContactData[this.getPaymentType].accountType
      ];
    },
    isFormValid() {
      if (this.formData.amount !== '' && this.formData.description !== '') {
        const amount = parseFloat('' + this.formData.amount);
        return amount >= 0.05;
      }
      return false;
    }
  },
  data() {
    return {
      formData: {
        amount: '0',
        description: ''
      },
      paymentStatus: '',
      paymentInfo: ''
    };
  },
  methods: {
    ...mapActions('pay', ['intrabankPay', 'achPay']),
    ...mapActions('account', ['getAccount']),
    ...mapMutations('transaction', ['setRecentTransactions']),
    cancel() {
      this.goToPreviousScreen();
    },
    next() {
      if (this.isFormValid) {
        const loader = this.showLoader();
        const action =
          this.getPaymentType === 'ach' ? 'achPay' : 'intrabankPay';
        this[action]({
          accountId: this.getSelectedAccount.id,
          contactId: this.getSelectedContactData.id,
          amount: '' + this.formData.amount,
          description: this.formData.description
        })
          .then((data) => {
            this.paymentInfo = data;
            this.paymentStatus = data.status;
            this.setRecentTransactions([]); //clear recent transactions
            setTimeout(() => {
              const loader2 = this.showLoader();
              this.getAccount(this.getSelectedAccount.id) //refresh the account
                .then(() => {})
                .catch((e) => {
                  console.log(e);
                  this.apiErrorMessage(e);
                })
                .finally(() => {
                  loader2.close();
                });
            }, 0);
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      }
    },
    done() {
      this.goToDashboard();
    }
  }
};
</script>
<style lang="scss">
.pay {
  &--completed {
    padding-top: 65px;

    .payment-done-header {
      text-align: center;
      padding-bottom: 48px;
    }

    .icon {
      font-size: 75px;
      margin-bottom: 36px;
      display: inline-block;

      .path1 {
        &:before {
          color: var(--color-green);
        }
      }
    }

    .title {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 16px;
    }

    .description {
      color: rgba(#3c3c43, 0.6);
      font-size: 14px;
      line-height: 20px;
    }

    .el-button {
      margin-top: auto;
    }
  }

  .widgetContainer__header-fixed {
    padding: 0;
  }

  .welcome__illustration {
    margin: 0 auto;
    width: 150px;
  }
  
  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
    margin-bottom: 10px;
  }

  &__header {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding: 6px 0;
  }

  &__details {
    .header-1 {
      text-transform: capitalize;
    }

    .sub-header {
      color: rgba(#3c3c43, 0.6);
      font-size: 13px;
      padding-bottom: 7px;
      text-transform: uppercase;
    }

    .contact-info {
      background: #fff;
      border-radius: 10px;
      padding: 10px 16px;
      margin-bottom: 30px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        .value {
          font-weight: 500;
          padding-left: 8px;
        }
      }
    }
  }

  .purpose-input {
    outline: none;
    border: none;
    text-align: right;
    input {
      text-align: right;
      font-weight: bold;
      color: #000000;
    }
  }
  .success-desc {
    font-size: 17px;
    color: #3c3c43;
    margin-bottom: 25px;
  }

  &__done-btn {
    width: 100%;
  }
}
</style>
